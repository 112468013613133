import { useForm, SubmitHandler } from "react-hook-form";
import { Navigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useAuthContext } from '../providers/AuthProvider';

export type Inputs = {
  email: string,
  password: string,
  remember: boolean,
};

function Login() {
  const { token, onLogin, error } = useAuthContext();

  const schema = yup.object().shape({
    email: yup.string().required("Email is required"),
    password: yup.string().required("Password is required"),
    remember: yup.boolean(),
  });

  const defaultValues = {
    email: "",
    password: "",
    remember: true,
  }

  const { register, handleSubmit, formState: { errors } } = useForm<Inputs>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const onSubmit: SubmitHandler<Inputs> = onLogin;

  if (token !== null) {
    return <Navigate to="/dashboard" replace />;
  } else {
    return (
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col grow h-full">
        <div className="flex flex-col items-stretch grow container mx-auto">
          <div className="flex flex-row grow items-stretch">
            <div className="my-4 self-center bg-slate-200/100 w-1/2 mx-auto shadow-inner rounded-lg">
              <div className="m-3 p-4 bg-white drop-shadow-lg rounded-md">
                <label className="block">
                  <span className="text-gray-700 font-bold">Email</span>
                  <input type="text" {...register("email")} className="block w-full mt-1 rounded-md bg-gray-100 border-slate-300 focus:border-slate-400 focus:bg-gray-50 focus:ring-0" />
                  {errors.email && <span className="text-red-600">{errors?.email?.message}</span>}
                </label>

                <label className="block mt-2">
                  <span className="text-gray-700 font-bold">Password</span>
                  <input type="password" {...register("password")} className="block w-full mt-1 rounded-md bg-gray-100 border-slate-300 focus:border-slate-400 focus:bg-gray-50 focus:ring-0" />
                  {errors.password && <span className="text-red-600">{errors?.password?.message}</span>}
                </label>

                <label className="mt-2 inline-flex items-center">
                  <input type="checkbox" {...register("remember")} className="rounded border-gray-300 text-sky-500 shadow-sm focus:border-sky-300 focus:ring focus:ring-offset-0 focus:ring-sky-200 focus:ring-opacity-50" />
                  <span className="ml-2 text-gray-700">Remember me</span>
                </label>

                <button type="submit" className="block text-base text-slate-50 mt-2 px-4 py-2 font-semibold rounded-md bg-sky-500 hover:text-white hover:bg-sky-600">Login</button>
                {error && <span className="inline-block mt-2 text-red-600">{error}</span>}
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default Login;
