import { useState } from 'react';
import { useTimeoutState } from '../utilities';
import { useForm, useFieldArray, Control, SubmitHandler } from "react-hook-form";
import { CheckIcon, ExclamationCircleIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { AuthError, useAuthContext } from '../providers/AuthProvider';
//import DxfApiProvider from '../providers/DxfApiProvider';
//import DxfFileProvider from '../providers/DxfFileProvider';
import DxfWasmProvider from '../providers/DxfWasmProvider';

export type Inputs = {
  uploads: File[],
  size: number,
  outputFilename: string,
  layers: {
    label: string;
    evib: number;
    colour: string;
  }[];
};

function Dashboard() {
  type ProcessingStatus = "processing" | "inactive";
  const [processingStatus, setProcessingStatus] = useState<ProcessingStatus>("inactive");

  type FileStatus = "start" | "completed" | "error";
  const [fileStatus, setFileStatus] = useTimeoutState<FileStatus>("start");

  const schema = yup.object().shape({
    uploads: yup.mixed().test("required", "File is required", (value) => {
      return value && value.length
    }),
    size: yup.number().typeError("Size must be a number")
      .min(0, "Size must be greater than 0").required("Size is required"),
    outputFilename: yup.string(),
    layers: yup.array()
      .of(
        yup.object().shape({
          label: yup.string().required("Label is required"),
          evib: yup.number().required("EVIB is required"),
          colour: yup.string().required("Colour is required")
        })
      )
      .min(1, "At least one layer is needed")
      .required("Layers are required")
  }).required();

  const layers = [
    { label: "L1", evib: 0, colour: "#FF0000" },
    { label: "L2", evib: 43, colour: "#008200" },
    { label: "L3", evib: 63, colour: "#00AA00" },
    { label: "L4", evib: 83, colour: "#00D200" },
    { label: "L5", evib: 103, colour: "#00FA00" },
    { label: "L6", evib: 122, colour: "#0000FF" },
  ]

  const defaultValues = {
    size: 0.125,
    outputFilename: "",
    layers: layers,
  };

  const { register, control, handleSubmit, formState: { errors }, watch } = useForm<Inputs>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const { fields, append, remove, swap } = useFieldArray({
    name: "layers",
    control
  });

  const watchLayers = watch("layers");

  const [uploadError, setUploadError] = useState<string>();

  const { onLogout } = useAuthContext();

  const onSubmit: SubmitHandler<Inputs> = async data => {
    setProcessingStatus("processing");

    //let dxfProcessor = new DxfApiProvider();
    //let dxfProcessor = new DxfFileProvider();
    let dxfProcessor = new DxfWasmProvider();

    dxfProcessor.process(data)
      .then(file => {
        const href = URL.createObjectURL(file);
        const link = document.createElement("a");

        link.href = href;
        link.setAttribute("download", data.outputFilename ? `${data.outputFilename}.dxf` : "file.dxf");
        document.getElementById("uploadForm")?.appendChild(link);
        link.click();

        document.getElementById("uploadForm")?.removeChild(link);
        URL.revokeObjectURL(href);

        setProcessingStatus("inactive");
        setFileStatus("completed", { timeout: 3000 });
      })
      .catch((error: any) => {
        if (error instanceof AuthError) {
          //onLogout();
          //return;
        }

        setFileStatus("error", { timeout: 10000 });
        console.log(error);
        setUploadError(`${error.message}`);
        setProcessingStatus("inactive");

        return;
      });
  };

  const onBlurEvib = () => {
    // Using browser increment button returns a string instead of number
    // @ts-ignore
    const watchEvibs = watchLayers.map(o => parseInt(o.evib));

    let swapped = false;

    // Bubble-sort list of Evibs to obtain indexes for react-hook-form swap method
    for (let i = 0; i < watchEvibs.length - 1; i++) {
      for (let j = 0; j < watchEvibs.length - i - 1; j++) {
        if (watchEvibs[j] > watchEvibs[j + 1]) {
          let temp = watchEvibs[j];
          watchEvibs[j] = watchEvibs[j + 1];
          watchEvibs[j + 1] = temp;
          swapped = true;

          swap(j, j + 1);
        }
      }

      if (swapped === false)
        break;
    }
  };

  const onClickAppend = () => {
    let newEvib: number;

    if (watchLayers.length)
      // Using browser increment button returns a string instead of number
      // @ts-ignore
      newEvib = parseInt(watchLayers[watchLayers.length - 1].evib) + 1;
    else
      newEvib = 0;

    append({
      label: "L" + (watchLayers.length + 1),
      evib: newEvib,
      colour: "#0000FF"
    })
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="uploadForm" className="flex flex-col grow h-full">
      <div className="flex flex-col items-stretch grow container mx-auto">
        <div className="flex flex-row grow items-stretch">
          <div className="my-4 self-center bg-slate-200/100 w-full shadow-inner rounded-lg">
            <div className="m-3 p-4 bg-white drop-shadow-lg rounded-md">
              <div className="flex flex-row gap-6 w-full">
                <div className="w-1/2">
                  <div className="">
                    <label>
                      <div className="text-gray-700 font-bold">Square Size</div>
                      <input {...register("size")} type="number" step="0.001" className="w-1/5 mt-1 rounded-md bg-slate-100 border-slate-300 focus:border-slate-400 focus:bg-slate-50 focus:ring-0" />
                      {errors.size && <div className="text-red-600">{errors?.size?.message}</div>}
                    </label>
                  </div>

                  <div className="mt-2">
                    <label>
                      <div className="text-gray-700 font-bold">Output Filename</div>
                      <input {...register("outputFilename")} type="text" className="w-8/12 mt-1 rounded-md bg-slate-100 border-slate-300 focus:border-slate-400 focus:bg-slate-50 focus:ring-0" />
                    </label>
                  </div>

                  <div className="w-full mt-2">
                    <label>
                      <div className="text-gray-700 font-bold">Easting, Northing, E<div className="inline-block align-bottom text-xs font-medium">VIB</div></div>
                      <span className="sr-only">Choose file to upload</span>
                      <input {...register("uploads")} type="file" multiple className="mt-1 text-base text-slate-500 file:cursor-pointer file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-base file:font-semibold file:bg-slate-300 file:text-slate-800 hover:file:bg-slate-400" />
                      {errors.uploads && <div className="text-red-600">{errors?.uploads?.message}</div>}
                    </label>
                  </div>
                </div>

                <div className="flex flex-col gap-2 w-1/2">
                  <div className="flex flex-col gap-2 w-full">
                    {fields.map((field, index) => {
                      return (
                        <div>
                          <div key={field.id} className="flex flex-row gap-2 w-full items-center">
                            <span className="text-gray-700 font-bold w-1/4">E<span className="inline-block align-bottom text-xs font-medium">VIB</span> Layer {index + 1}</span>

                            <input
                              type="text"
                              {...register(`layers.${index}.label` as const, { required: true })}
                              className="mt-1 w-1/4 block rounded-md bg-slate-100 border-slate-300 focus:border-slate-400 focus:bg-slate-50 focus:ring-0"
                            />

                            <input
                              type="number"
                              {...register(`layers.${index}.evib` as const, { required: true })}
                              className="mt-1 w-1/4 block rounded-md bg-slate-100 border-slate-300 focus:border-slate-400 focus:bg-slate-50 focus:ring-0"
                              onBlur={onBlurEvib}
                            />

                            <div className="w-1/4 flex items-center">
                              <input
                                type="color"
                                {...register(`layers.${index}.colour` as const, { required: true })}
                                className="inline-block h-8 p-0 border-2"
                              />

                              <button
                                type="button"
                                className="inline-block text-base text-slate-50 mx-2 px-4 py-2 font-semibold rounded-md bg-red-500 hover:text-white hover:bg-red-600"
                                onClick={() => remove(index)}
                              >
                                <XMarkIcon className="inline-block h-6 w-6" />
                              </button>
                            </div>
                          </div>
                          {errors?.layers?.[index] &&
                            <div className="flex">
                              <div className="w-1/4">&nbsp;</div>
                              <div className="w-1/4">
                                {errors?.layers?.[index]?.label && <div className="text-red-600">{errors?.layers?.[index]?.label?.message}</div>}
                              </div>
                              <div className="w-1/4">
                                {errors?.layers?.[index]?.evib && <div className="text-red-600">Evib must be a number</div>}
                              </div>
                            </div>
                          }
                        </div>
                      );
                    })}
                    {errors.layers && <div className="text-red-600">{errors?.layers?.message}</div>}
                  </div>

                  <div>
                    <button
                      type="button"
                      className="inline-block text-base text-slate-50 mr-4 px-4 py-2 font-semibold rounded-md bg-sky-500 hover:text-white hover:bg-sky-600"
                      onClick={onClickAppend}
                    >
                      <PlusIcon className="inline-block h-6 w-6" />
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full p-4 bg-white">
        <div className="container mx-auto px-4">
          <button type="submit" className="inline-block text-base text-slate-50 mr-4 px-4 py-2 font-semibold rounded-md bg-sky-500 hover:text-white hover:bg-sky-600">Process</button>

          {processingStatus === "processing" &&
            <svg className="inline-block animate-spin -ml-1 mr-3 h-5 w-5 text-orange-900" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>}

          <CheckIcon className={`inline-block h-6 w-6 text-green-700 transition transition-opacity${fileStatus === "completed" ? " opacity-100" : " opacity-0"}`} />

          <span className={`transition transition-opacity${fileStatus === "error" ? " opacity-100" : " opacity-0"}`}>
            <ExclamationCircleIcon className="inline-block h-6 w-6 text-red-600" />
            <span className="inline-block pl-2 text-red-600">{uploadError}</span>
          </span>
        </div>
      </div>
    </form >
  );
}

export default Dashboard;
