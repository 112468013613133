import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

const client = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    "Accept": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
});

class Api {
  /**
   * Interface to preinitialised Axios object. Checks XSRF token and requests new token if necessary.
   *
   * @param {string} url - URL to query
   * @param {AxiosRequestConfig} config - Additional Axios configuration
   * @returns {Promise<AxiosResponse<any, any>>} Axios instance
   */
  public async request(url: string, config: AxiosRequestConfig): Promise<AxiosResponse<any, any>> {
    if (config.method !== "GET" && !document.cookie.includes("XSRF-TOKEN")) {
      try {
        await client.get("/sanctum/csrf-cookie");
      } catch (e) { }
    }

    return client.request({ ...config, url });
  }
}

export default new Api();
