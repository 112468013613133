import React, { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../api';
import { Inputs as LoginInputs } from '../pages/Login';
import { useCookies } from 'react-cookie';

interface IAuthContext {
  token: string | null,
  onLogin(data: LoginInputs): Promise<void>,
  onLogout(): void,
  error: string | null,
}

const AuthContext = React.createContext<IAuthContext | null>(null);

/**
 * Provides authentication context for child elements below it in tree.
 * 
 * @remarks useAuthContext() gives access to available interface properties: { token, onLogin, onLogout, error }.
 * @param {any} children JSX child elements.
 * @returns {any} JSX child elements.
 */
function AuthProvider({ children }: any): any {
  const navigate = useNavigate();
  const location = useLocation();

  const [cookies, setCookie] = useCookies(["token"]);

  let _token;

  if (typeof cookies.token === "undefined" || cookies.token === "null")
    _token = null;
  else
    _token = cookies.token;

  const [token, setToken] = useState<string | null>(_token);
  const [error, setError] = useState<string | null>(null);

  const handleLogin = async (data: LoginInputs) => {
    try {
      await api.request("/login", {
        method: "POST",
        data: data,
      });

      setToken("12345");
      setCookie("token", "12345");

      const origin = location.state?.from?.pathname || "/dashboard";
      navigate(origin);
    } catch (error: any) {
      console.log(error.response);
      setError(error.response.data.message);

      setToken(null);
      setCookie("token", null);
    }
  };

  const handleLogout = async () => {
    try {
      await api.request("/logout", {
        method: "POST",
      });

      setToken(null);
      setCookie("token", null);
      navigate("/login");
    } catch (error: any) {
      console.log(error.message);
      setToken(null);
      setCookie("token", null);
      navigate("/login");
    }
  };

  const value = {
    token: token,
    onLogin: handleLogin,
    onLogout: handleLogout,
    error: error,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

/**
 * Returns interface object giving access to AuthProvider properties.
 *
 * @returns AuthProvider IAuthContext interface: { token, onLogin, onLogout, error }.
 * @throws Throws exception if context could not be created.
 */
export function useAuthContext(): IAuthContext {
  let context = useContext(AuthContext);

  if (context === null) {
    throw Error("Auth context error");
  }

  return context;
}

export class AuthError extends Error {
  constructor(msg: string) {
    super(msg);
    Object.setPrototypeOf(this, AuthError.prototype);
  }
}

export default AuthProvider;
