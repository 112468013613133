import { Navigate, useLocation } from 'react-router-dom';
import { useAuthContext } from './AuthProvider';

/**
 * Prevents access to route unless token is set.
 *
 * @param {any} children JSX child elements
 * @returns {any} JSX child elements
 */
function ProtectedRoute({ children }: any): any {
  const { token } = useAuthContext();
  const location = useLocation();

  if (token === null) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  return children;
}

export default ProtectedRoute;
