import IDxfProcessor from './IDxfProcessor';
import { Inputs } from '../pages/Dashboard';
import { getCookie } from '../utilities';
import { AuthError } from '../providers/AuthProvider';

window.URL = window.URL || window.webkitURL;

var work = `
importScripts('${document.location.protocol}//${document.location.host}/pkg/dxfrenderer.js');

onmessage = async event => {
  const { process } = wasm_bindgen;

  await wasm_bindgen('${document.location.protocol}//${document.location.host}/pkg/dxfrenderer_bg.wasm');

  let {
    files,
    size,
    outputFilename,
    layer1,
    layer2,
    layer3,
    layer4,
    layer5,
    colour1,
    colour2,
    colour3,
    colour4,
    colour5,
    colour6,
    xsrf,
  } = event.data;

  size = parseFloat(size);
  layer1 = parseInt(layer1);
  layer2 = parseInt(layer2);
  layer3 = parseInt(layer3);
  layer4 = parseInt(layer4);
  layer5 = parseInt(layer5);
  colour1 = parseInt(colour1);
  colour2 = parseInt(colour2);
  colour3 = parseInt(colour3);
  colour4 = parseInt(colour4);
  colour5 = parseInt(colour5);
  colour6 = parseInt(colour6);
  let output_filename = outputFilename;

  process({
    files,
    size,
    output_filename,
    auto_cad_version,
    layer1,
    layer2,
    layer3,
    layer4,
    layer5,
    colour1,
    colour2,
    colour3,
    colour4,
    colour5,
    colour6,
    xsrf,
  }).then(outputFileString => {
    postMessage({
      outputFileString
    });
  }).catch(error => {
    setTimeout(function () { throw error; });
  });
};
`;

class DxfWasmProvider implements IDxfProcessor {
  async process(data: Inputs): Promise<Blob> {
    let files: string[] = [];

    for (var i = 0; i < data.uploads.length; i++) {
      await data.uploads[i].text().then(text => {
        files.push(text);
      });
    }

    return new Promise<Blob>((resolve, reject) => {
      //var blob = new Blob([work], {type: "application/javascript" });
      //const worker = new window.Worker(URL.createObjectURL(blob));
      const worker = new window.Worker("./DxfWasmWorker.js");

      const sortedLayers = [...data.layers].sort((a, b) => a.evib - b.evib);
      const labels = sortedLayers.map(o => { return o.label; });
      const evibs = sortedLayers.map(o => { return o.evib; });
      const colours = sortedLayers.map(o => { return o.colour; });

      worker.postMessage({
        files: files,
        size: data.size,
        outputFilename: data.outputFilename,
        labels: labels,
        evibs: evibs,
        colours: colours,
        xsrf: getCookie("XSRF-TOKEN"),
      });

      worker.onmessage = (e) => {
        let file = e.data.outputFileString;
        worker.terminate();
        resolve(file);
      };

      worker.onerror = (err) => {
        worker.terminate();
        var authError = new AuthError("Invalid status");
        reject(authError);
      }
    });
  }
}

export default DxfWasmProvider;
