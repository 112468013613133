import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import ErrorNotFound from './pages/ErrorNotFound';
import Login from './pages/Login';
import AuthProvider from './providers/AuthProvider';
import ProtectedRoute from './providers/ProtectedRoute';
import Header from './components/Header';

function App() {
  useEffect(() => {
    document.title = "IGR MAP";
  }, []);

  return (
    <AuthProvider>
      <div className="min-h-screen bg-gradient-to-b from-indigo-50 to-stone-200">
        <div className="flex flex-col min-h-screen">

          <Header />

          <Routes>
            <Route
              index
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route path="login" element={<Login />} />
            <Route path="*" element={<ErrorNotFound />} />
          </Routes>

        </div>
      </div>
    </AuthProvider>
  );
}

export default App;
