import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

function ErrorNotFound() {
  return (
    <div className="flex items-stretch grow">
      <div className="self-center flex flex-col items-stretch grow">
        <div className="self-center">
          <div className="text-center">
            <ExclamationTriangleIcon className="inline-block h-24 w-24 text-red-600" />
          </div>
          <div className="text-center text-red-600 font-bold">Not Found</div>
        </div>
      </div>
    </div>
  );
}

export default ErrorNotFound;
