import { useAuthContext } from '../providers/AuthProvider';

function Header() {
  const { token, onLogout } = useAuthContext();

  return (
    <div className="bg-gradient-to-b from-slate-400 to-slate-500 border-b-2 border-slate-600 drop-shadow-xl">
      <div className="container mx-auto py-5">
        <div className="flex flex-row">
          <h1 className="grow text-4xl font-normal text-left text-white px-4 self-center">
            IGR-MAP
          </h1>
          <div className="flex-none self-center px-4">
            {token && (<button onClick={onLogout} className="block text-base text-slate-50 px-4 py-2 font-semibold rounded-md bg-green-500 hover:text-white hover:bg-green-600">Logout</button>)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
